import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Button, Spinner, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css"; // Ensure this file has correct CSS
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blinkingIds, setBlinkingIds] = useState([]); // State to track blinking tests
  const navigate = useNavigate();
  const [hoveredId, setHoveredId] = useState(null);

  const handleLogin = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await fetch("https://drdileepgangwarinstitute.com/testresult/welcome/get_tests");
        const responseData = await response.json();

        const testsArray = responseData.data || [];

        if (!Array.isArray(testsArray)) {
          throw new Error("Invalid data format: Expected an array");
        }

        const sortedTests = testsArray.sort((a, b) => new Date(b.entrydate) - new Date(a.entrydate));
        setTests(sortedTests);

        // Get today's date in the same format as test.date (assuming 'YYYY-MM-DD')
        const today = new Date().toISOString().split("T")[0];

        // Find tests where the date matches today's date and mark them to blink
        const idsToBlink = sortedTests
          .filter((test) => test.entrydate === today)
          .map((test) => test.id);
        setBlinkingIds(idsToBlink);

      } catch (error) {
        console.error("Failed to fetch tests:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTests();
  }, []);

  if (loading) {
    return (
      <Container className="text-center">
        <Spinner animation="border" />
        <p>Loading tests...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }

  return (
    <Container>
      {/* Top Bar */}
      <div style={{ color: "rgb(99 33 39)" }} className="header p-3 d-flex justify-content-between align-items-center rounded">
        <div className="user-details">
          <h5 className="mb-1">Name: {localStorage.getItem("name")}</h5>
          <h6 className="mb-1">Father Name: {localStorage.getItem("father")}</h6>
          <h6 className="mb-0">Roll No.: {localStorage.getItem("rollno")}</h6>
        </div>
        <Button variant="light" className="fw-bold" onClick={handleLogin}>
          Logout
        </Button>
      </div>

      {/* Top Image */}
      <img
        src="https://qacraft.com/wp-content/uploads/2022/12/What-is-Test-Plan-1.png"
        alt="Top Banner"
        className="img-fluid mb-4"
        style={{ borderRadius: "10px" }}
      />

      {/* Tests List */}
      <ListGroup>
      {tests.map((test) => (
        <Link
          to={`/detail/${test.id}`} // Clicking anywhere redirects
          key={test.id}
          style={{ textDecoration: "none", color: "inherit" }} // Keeps default text style
        >
          <ListGroup.Item
            className="d-flex justify-content-between align-items-start mb-2 shadow-sm position-relative"
            style={{
              borderRadius: "10px",
              padding: "15px",
              transition: "opacity 0.3s ease-in-out",
              cursor: "pointer", // Indicate clickable behavior
              opacity: hoveredId === test.id ? 0.7 : 1, // Opacity effect on hover
            }}
            onMouseEnter={() => setHoveredId(test.id)}
            onMouseLeave={() => setHoveredId(null)}
          >
            <div>
              <ul className="list-unstyled mb-0">
                <li>
                  <small style={{ fontSize: "12px" }}>({test.showName})</small>
                </li>
                <li>
                  <div className="d-flex">
                    <strong style={{ width: "100px" }}>Test Code:</strong>
                    <span>{test.name}</span>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <strong style={{ width: "100px" }}>Test Date:</strong>
                    <span>{test.date}</span>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <strong style={{ width: "100px" }}>Results:</strong>
                    <span>{test.resultAnnounce}</span>
                  </div>
                </li>
              </ul>
            </div>
            {/* Show button only when hovered */}
            {hoveredId === test.id && (
              <div className="align-items-right">
                <button
                  className="btn btn-primary px-3 py-1"
                  style={{
                    borderRadius: "20px",
                    fontWeight: "bold",
                    transition: "opacity 0.3s ease-in-out",
                  }}
                >
                  View Detail
                </button>
              </div>
            )}
          </ListGroup.Item>
        </Link>
      ))}
    </ListGroup>
    </Container>
  );
};

export default Dashboard;
