import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Document, Page } from 'react-pdf';  // Corrected import
import { pdfjs } from 'react-pdf';  // Import pdfjs to set the worker source

// Set the workerSrc globally
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ShowPDFPage = () => {
  const { testId } = useParams();

  // Replace with the actual URL of the PDF
  const pdfUrl = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";

  const handleDownload = () => {
    // Trigger download of the PDF test paper
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `Test_${testId}_Paper.pdf`;
    link.click();
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3 className="text-center">Test Paper for Test {testId}</h3>
            </Card.Header>
            <Card.Body>
              <div className="text-center mb-4">
                {/* <Document file={pdfUrl}>
                  <Page pageNumber={1} />
                </Document> */}
              </div>
              <p>
                You can view the PDF test paper above and download it by clicking the button below.
              </p>
              <Button variant="primary" onClick={handleDownload}>
                Download Test Paper
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ShowPDFPage;
