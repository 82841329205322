import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

const LoginPage = () => {
  const [step, setStep] = useState("login");
  const [rollno, setRollno] = useState("");
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState("");
  const [name, setName] = useState("");
  const [father, setFather] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [timer, setTimer] = useState(120);
  const [timerRunning, setTimerRunning] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let timerInterval;

    if (timerRunning && timer > 0) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      clearInterval(timerInterval);
      alert("OTP has expired. Please request a new one.");
      setStep("login");
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [timer, timerRunning]);

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader

    try {
      const response = await fetch("https://drdileepgangwarinstitute.com/testresult/welcome/matchRollNo", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rollno }),
      });

      const data = await response.json();

      if (data.success) {
        setGeneratedOtp(data.otp);
        setName(data.name);
        setMobile(data.mobile);
        setFather(data.fathername);
        setRollno(data.roll_no);
        setStep("otp");
      } else {
        alert(data.message || "Roll number not found.");
      }
    } catch (error) {
      console.error("Error during roll number verification:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loader after response
    }
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    const otpLast4 = otp.slice(-4);
    const generatedOtpLast4 = generatedOtp.slice(-4);

    if (otpLast4 === generatedOtpLast4) {
      setStep("dashboard");
      setTimerRunning(false);
      navigate("/dashboard");
      localStorage.setItem("rollno", rollno);
      localStorage.setItem("mobile", otp);
      localStorage.setItem("name", name);
      localStorage.setItem("father", father);
      localStorage.setItem("studentmobile", mobile);
    } else {
      alert("Incorrect mobile number. Please try again.");
      setStep("login");
      setTimerRunning(false);
    }
  };

  const handleBackToPhone = () => {
    setStep("login");
  };

  return (
    <div className="container vh-100 d-flex justify-content-center align-items-center bg-light">
      {step === "login" && (
        <div className="card shadow p-4" style={{ width: "22rem", borderRadius: "10px" }}>
          <div className="text-center mb-4">
            <img
              src="https://drdileepgangwarinstitute.com/testresult/assets/images/dash.png"
              alt="New Speed Institute Logo"
              style={{ width: "150px", height: "160px" }}
            />
            
          </div>
        
          <form onSubmit={handlePhoneSubmit}>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Roll No.
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                placeholder="Enter your roll No"
                value={rollno}
                onChange={(e) => setRollno(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? (
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Proceed"
              )}
            </button>
          </form>
        </div>
      )}

      {step === "otp" && (
        <div className="card shadow p-4" style={{ width: "22rem", borderRadius: "10px" }}>
          <h4 className="text-center mb-4">Authentication</h4>
          <form onSubmit={handleOtpSubmit}>
            <div className="mb-3">
              <label htmlFor="otp" className="form-label">
                Enter Register Mobile No.
              </label>
              <input
                type="text"
                className="form-control"
                id="otp"
                placeholder="XXXXXX1234"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Login
            </button>
            <button
              type="button"
              className="btn btn-secondary w-100 mt-2"
              onClick={handleBackToPhone}
            >
              Back to Roll Number
            </button>
          </form>
        </div>
      )}

      {step === "dashboard" && (
        <div className="text-center">
          <h1>Welcome to the Dashboard</h1>
          <p>Your phone number: {rollno}</p>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
