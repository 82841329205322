import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

const OMRSheetPage = () => {
  const { testId } = useParams(); // Get testId from route parameters
  const rollno = localStorage.getItem("rollno"); // Get rollno from localStorage

  const [testDetails, setTestDetails] = useState(''); // State for test details
  const [testcode, setTestcode] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  
  // Replace with the actual image URL
 // const omrSheetImage = "https://via.placeholder.com/600x400?text=OMR+Sheet";
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        
        const response = await fetch(
          `https://drdileepgangwarinstitute.com/testresult/welcome/get_omrsheet?testId=${testId}&rollno=${rollno}`

        ); // Replace with your API endpoint
        const data = await response.json();
      
        if (data) {
          setTestDetails(data.imageUrl); 
          setTestcode(data.testid);// Set the fetched data to state
        } else {
          setError("Failed to fetch test details");
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [testId, rollno]);
  const handleDownload = () => {
    // Trigger download of the OMR sheet
    const link = document.createElement("a");
    link.href = testDetails;
    link.download = `OMR_Sheet_Test_${testcode}.jpg`;
    link.click();
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header>
              <h3 className="text-center">OMR Sheet Test Code {testcode}</h3>
            </Card.Header>
            <Card.Body className="text-center">
            {testDetails ? (
  <img
    src={testDetails}
    alt={`OMR Sheet for Test ${testcode}`}
    className="img-fluid mb-4"
    onError={(e) => {
      e.target.onerror = null; // Prevent infinite loop in case fallback fails
      e.target.style.display = "none"; // Hide the broken image
      const message = document.getElementById("fallbackMessage");
      if (message) message.style.display = "block";
    }}
  />
) : (
  <p id="fallbackMessage" style={{ display: "block", color: "red", fontWeight: "bold" }}>
    You were abset for the test.
  </p>
)}

              <p>
                Download the OMR sheet for Test Code {testcode} by clicking the button
                below.
              </p>
              <Button variant="primary" onClick={handleDownload}>
                Download OMR Sheet
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OMRSheetPage;
