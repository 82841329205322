import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./components/LoginPage.js";
import Dashboard from "./components/Dashboard";
import DetailPage from "./components/DetailPage";
import OMRSheetPage from "./components/OMRSheetPage";
import ShowPDFPage from "./components/ShowPDFPage";
import ResponsePage from "./components/ResponsePage";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if user is logged in based on localStorage
    const rollno = localStorage.getItem("rollno");
    const mobile = localStorage.getItem("mobile");

    if (rollno && mobile) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <Router>
      <Routes>
        
        {/* Redirect to dashboard if logged in, otherwise to login */}
        <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/detail/:testId" element={<DetailPage />} />
        <Route path="/omrsheet/:testId" element={<OMRSheetPage />} />
        <Route path="/showpdf/:testId" element={<ShowPDFPage />} />
        <Route path="/response/:testId/:testcode" element={<ResponsePage />} />
      </Routes>
    </Router>
  );
};

export default App;