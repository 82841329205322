import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const DetailPage = () => {
  const { testId } = useParams(); // Get testId from route parameters
  const rollno = localStorage.getItem("rollno"); // Get rollno from localStorage

  const [testDetails, setTestDetails] = useState([]); // State for test details
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state
  const [rno, setRno] = useState(''); 
  const [testFile, setTesFile] = useState([]); 
  // Fetch data from API
  useEffect(() => {
    
    setRno(rollno);
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://drdileepgangwarinstitute.com/testresult/welcome/get_test_result?testId=${testId}&rollno=${rollno}`

        ); // Replace with your API endpoint
        const data = await response.json();

        if (data) {
          setTestDetails(data); // Set the fetched data to state
        } else {
          setError("Notice:You were absent for the test.");
        }
      } catch (err) {
        setError("Notice:You were absent for the test.");
       // setTestDetails();
      } finally {
        setLoading(false);
      }
    };


    const fetchNotice = async () => {
      try {
          setLoading(true);
  
          // Fetching data from the API
          const response = await fetch(
              `https://drdileepgangwarinstitute.com/testresult/welcome/get_test_notice?testId=${testId}&rollno=${rollno}`
          ); // Replace with your API endpoint
  
          const data = await response.json();
  
          // Check if the API response contains a message or test details
          if (data) {
              if (data.message) {
                  // If the API includes a message, display it
                  setError(data.message);
              } else {
                  // If valid test details are fetched, set them in state
                  setError('Notice:Your were absent in test.');
              }
          } else {
              // Handle cases where no valid data is returned
            
          }
      } catch (err) {
          // Handle any fetch or network errors
         
      } finally {
          // Always set loading to false when the operation is complete
          setLoading(false);
      }
  };

  const fetchfile = async () => {
    try {
        setLoading(true);

        // Fetching data from the API
        const response = await fetch(
            `https://drdileepgangwarinstitute.com/testresult/welcome/get_test_file?testId=${testId}`
        ); // Replace with your API endpoint

        const data = await response.json();
        setTesFile(data);
      
        // Check if the API response contains a message or test details
       
    } catch (err) {
        // Handle any fetch or network errors
       
    } finally {
        // Always set loading to false when the operation is complete
        setLoading(false);
    }
};
  
    fetchDetails();
    fetchfile();
    fetchNotice();
  }, [testId, rollno]);

  if (loading) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  // if (error) {
  //   return <div className="text-center mt-4 text-danger">{error}</div>;
  // }

  return (
    <div className="container mt-4">
     
      <h2 className="text-center mb-4"> Roll No {rno}</h2>
      
      <div className="row g-4">
        {/* Test Series Details Card */}
       

        {/* Test Result Card */}
        <div className="col-md-12">
          <Card className="shadow" style={{ backgroundColor: "#e9ecef" }}>
            <Card.Body>
              <Card.Title className="text-center">Test Result</Card.Title>
              <Card.Text>
              <div className="col-md-12">
        
          <div className="p-2"><div dangerouslySetInnerHTML={{ __html: error }}></div></div>
           
       
        </div>
  <div className="d-flex"  style={{backgroundColor:"gainsboro", padding: "8px" }}>
    <strong style={{ width: "150px" }}>Test Code:</strong>
    <span>{testFile?.name || "N/A"}</span>
  </div>
  <div className="d-flex"  style={{ padding: "8px" }}>
    <strong style={{ width: "150px" }}>Right Questions:</strong>
    <span>{testDetails?.right_mark || "N/A"}</span>
  </div>
  <div className="d-flex"  style={{ backgroundColor:"gainsboro", padding: "8px" }}>
    <strong style={{ width: "150px" }}>Wrong Questions:</strong>
    <span>{testDetails?.wrong_mark || "N/A"}</span>
  </div>
  <div className="d-flex"  style={{ padding: "8px" }}>
    <strong style={{ width: "150px" }}>Left Questions:</strong>
    <span>{testDetails?.left_mark || "N/A"}</span>
  </div>
  <div className="d-flex"  style={{backgroundColor:"gainsboro", padding: "8px" }}>
    <strong style={{ width: "150px" }}>Max. Marks:</strong>
    <span>{testDetails?.total_mark || "N/A"}</span>
  </div>
  <div className="d-flex"  style={{ padding: "8px" }}>
    <strong style={{ width: "150px" }}>Rank:</strong>
    <span>{testDetails?.rank || "N/A"}</span>
  </div>


              </Card.Text>
            </Card.Body>
          </Card>
        </div>
    
        {/* Action Buttons Card */}
        <div className="col-md-12">
          <Card className="shadow" style={{ backgroundColor: "#d1e7dd" }}>
            <Card.Body>
              <Card.Title className="text-center">Actions</Card.Title>
              <div className="d-flex flex-column align-items-center gap-2">
                <Button as={Link}  to={`/omrsheet/${testId}`} variant="primary" className="w-100">
                  OMR Sheet
                </Button>
                <Button as={Link}  to={`/response/${testId}/${testFile.name}`} variant="success" className="w-100">
                  Response Result
                </Button>
               

                <Button 
  variant="warning" 
  className="w-100"
  onClick={() => window.open(`https://docs.google.com/gview?embedded=true&url=http://drdileepgangwarinstitute.com/testresult/testpaper/${testFile.img_url}`, '_blank')}
>
Test Paper PDF
</Button>
                <Button 
  variant="success" 
  className="w-100"
  onClick={() => window.open(`https://docs.google.com/gview?embedded=true&url=http://drdileepgangwarinstitute.com/testresult/testpaper/${testFile.answer_key_pdf}`, '_blank')}
>
  Answer key PDF
</Button>

                <Button as={Link}  to= {testFile.video_solution} variant="danger" className="w-100">
                 Video Solution
                </Button>
           
               
                <Button 
  variant="primary" 
  className="w-100"
  onClick={() => window.open(`https://docs.google.com/gview?embedded=true&url=http://drdileepgangwarinstitute.com/testresult/testpaper/${testFile.top_30}`, '_blank')}
>
Top 30 Rank
</Button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;

