import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";

const ResponsePage = () => {
  const { testId,testcode } = useParams(); // Extract testId from the route
  const rollNo = localStorage.getItem("rollno"); // Get rollNo from localStorage
  const [questions, setQuestions] = useState([]); // Store question objects
  const [isLoading, setIsLoading] = useState(true);

  // Local mapping of question texts
  const questionTextMapping = {
    Q1: "What is React?",
    Q2: "What is JSX?",
    Q3: "What is a state in React?",
    Q4: "What are props in React?",
    Q78: "Explain the Virtual DOM in React.",
    // Add more mappings as needed
  };

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const apiEndpoint = `https://drdileepgangwarinstitute.com/testresult/welcome/get_test_response?testId=${testId}&rollno=${rollNo}`;
        const response = await fetch(apiEndpoint);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data) {
          // Transform the API data into a list of questions with text and status
          const questionList = Object.entries(data)
            .filter(([key]) => key.startsWith("Q"))
            .map(([key, value]) => ({
              text:`${key}`, // Use question text if available
              status:
                value === 'R'
                  ? "right"
                  : value === 'W'
                  ? "wrong"
                  : "left",
            }));

          setQuestions(questionList);
        } else {
          console.error("No data found for the given testId and rollNo.");
        }
      } catch (error) {
        console.error("Error fetching API data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [testId, rollNo]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!questions.length) {
    return <div>No data available for the given test code and roll number.</div>;
  }

  // Calculate counts based on the question statuses
  const leftCount = questions.filter((q) => q.status === "left").length;
  const rightCount = questions.filter((q) => q.status === "right").length;
  const wrongCount = questions.filter((q) => q.status === "wrong").length;

  return (
    <Container className="mt-5">
      <h2 className="text-center">Response Test Code {testcode}</h2>
      <Row className="mt-4">
        {questions.map((question, index) => (
          <Col key={index} sm={4} md={3} lg={2} className="mb-3">
            <Card
              style={{
                backgroundColor:
                  question.status === "left"
                    ? "#f0f0f0"
                    : question.status === "right"
                    ? "lightgreen"
                    : "salmon",
                cursor: "pointer",
              }}
            >
              <Card.Body className="text-center">
                <h5
                  style={{
                    color:
                      question.status === "left"
                        ? "gray"
                        : question.status === "right"
                        ? "green"
                        : "red",
                  }}
                >
                  {question.text}
                </h5>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Card className="mt-4">
        <Card.Body>
          <Row>
            <Col className="text-center">
              <h5>Left: {leftCount}</h5>
            </Col>
            <Col className="text-center">
              <h5>Right: {rightCount}</h5>
            </Col>
            <Col className="text-center">
              <h5>Wrong: {wrongCount}</h5>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ResponsePage;
